import { BlockComponent } from '~/lib/storyblok'
import { blocks } from '~/storyblok/blocks'
import type { Chef } from './types'
import { Intro, Container, BackButton } from '~/components'

import * as styles from './Chef.css'

type Props = {
  story: Chef
}

export function Chef({ story }: Props) {
  return (
    <>
      <div className={styles.intro}>
        <Intro title={story.name} />
      </div>
      <Container>
        <div className={styles.back}>
          <BackButton>zurück</BackButton>
        </div>
      </Container>
      {story.blocks?.map((block) => (
        <BlockComponent block={block} blocks={blocks} key={block.id} parentContentType="Event" />
      ))}
    </>
  )
}
