import { StoryblokLink } from './types'

export function hasLink(link: StoryblokLink): boolean {
  if (!link) {
    return false
  }

  if (link.linktype === 'story' && !link.id) {
    return false
  }

  return link.url || link.cached_url ? true : false
}
