export const relations = [
  'Event.location',
  'EventsBlock.events',
  'LocationsBlock.locations',
  'EventsOverviewBlock.events',
  'LocationsOverviewBlock.locations',
  'ChefsOverviewBlock.chefs',
  'HeroBlock.events',
  'HeroBlock.event',
]
