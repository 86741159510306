import React from 'react'
import Image from 'next/image'
import classNames from 'classnames'
import { Block } from '~/components'
import type { GalleryBlock } from './types'
import * as styles from './GalleryBlock.css'
import { Swiper as SwiperType } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { StoryblokAsset, transformImage } from '~/lib/storyblok'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'

type Props = {
  block: GalleryBlock
}

function getOrientationClass(orientation: string): string {
  switch (orientation) {
    case 'landscape':
      return 'imageLandscape'
    case 'portrait':
      return 'imagePortrait'
    default:
      return 'imageSquare'
  }
}

export function GalleryBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <div className={styles.wrapper}>
        <div className={styles.canvas}>
          <Swiper
            modules={[Autoplay]}
            initialSlide={0}
            slidesPerView="auto"
            spaceBetween={20}
            loop={false}
            onClick={(swiper: any) => {
              swiper.slideNext()
            }}
            slidesOffsetAfter={20}
            slidesOffsetBefore={20}
            resistanceRatio={0}
            autoplay={{
              delay: 0,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            speed={7000}
          >
            {block.images.map((image, index) => (
              <SwiperSlide key={index} className={styles.slide}>
                <img
                  src={image.url}
                  alt={image.alt}
                  width={image.width}
                  height={image.height}
                  className={styles.image}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Block>
  )
}
