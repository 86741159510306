import type { StoryblokBlock, Block, TransformContext } from './types'
import { getDefaultBlockProperties } from './getDefaultBlockProperties'
import { transformBlockOptions } from '~/storyblok/transformBlockOptions'

// TODO: Comments... what does this do?
function customTransformFn(blockMapEntry: any) {
  return (
    typeof blockMapEntry === 'object' &&
    blockMapEntry !== null &&
    'transform' in blockMapEntry &&
    typeof blockMapEntry.transform === 'function'
  )
}

// TODO: blockMap types
export async function transformBlocks(storyblokBlocks: StoryblokBlock[], context: TransformContext): Promise<Block[]> {
  const blocks: Block[] = []

  // storyblokBlocks can be missing (no content).
  if (!Array.isArray(storyblokBlocks)) {
    return blocks
  }

  for (const storyblokBlock of storyblokBlocks) {
    const name = storyblokBlock.component
    if (name in context.components) {
      const defaultProps = getDefaultBlockProperties(storyblokBlock)
      const options = transformBlockOptions(storyblokBlock)

      // Use the custom transform function should there be one. Otherwise, just give the full block.
      const transformedBlock = customTransformFn(context.components[name])
        ? await context.components[name].transform(storyblokBlock, {
            props: defaultProps,
            options,
            context,
            ...context,
          })
        : { ...storyblokBlock, ...getDefaultBlockProperties(storyblokBlock) }

      blocks.push(transformedBlock)
    } else {
      console.info(`transformBlocks(): block '${name}' not found`)
    }
  }

  return blocks
}
