import React from 'react'
import Image from 'next/image'

import { Block, Container, Heading, RichText } from '~/components'
import type { HostsBlock } from './types'

import * as styles from './HostsBlock.css'

type Props = {
  block: HostsBlock
}

export function HostsBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.content}>
          <div className={styles.header}>
            <Heading>{block.title}</Heading>
          </div>
          <div className={styles.main}>
            <div className={styles.hosts}>
              {block.hosts.map((host) => (
                <div key={host.id} className={styles.host}>
                  <div className={styles.image}>
                    <Image src={host.image.url} alt={host.image.alt} layout="fill" />
                  </div>
                  <RichText content={host.text} className={styles.text} />
                  <RichText content={host.meta} className={styles.meta} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Block>
  )
}
