import React from 'react'
import { Block, Container } from '~/components'
import Lightbox from 'yet-another-react-lightbox'
import { RowsPhotoAlbum } from 'react-photo-album'
import 'react-photo-album/rows.css'
import type { GalleryGridBlock } from './types'
import * as styles from './GalleryGridBlock.css'
import 'swiper/css'
import { transformImageResize } from '~/lib/storyblok/transformImageResize'

type Props = {
  block: GalleryGridBlock
}

const breakpoints = [3840, 1920, 1080, 640, 384, 256, 128]

export function GalleryGridBlock({ block }: Props) {
  const [index, setIndex] = React.useState(-1)

  const slides = block.images.map((image) => ({
    src: transformImageResize({ ...image, filename: image.asset }, { width: 2560 }).url,
    width: image.width,
    height: image.height,
    srcSet: breakpoints.map((breakpoint) => ({
      src: transformImageResize({ ...image, filename: image.asset }, { width: breakpoint }).url,
      width: breakpoint,
      height: Math.round((image.height / image.width) * breakpoint),
    })),
  }))

  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.content}>
          <div className={styles.main}>
            <RowsPhotoAlbum photos={slides} targetRowHeight={250} onClick={({ index: current }) => setIndex(current)} />
            <Lightbox
              index={index}
              slides={slides}
              open={index >= 0}
              close={() => setIndex(-1)}
              styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .8)' } }}
            />
          </div>
        </div>
      </Container>
    </Block>
  )
}
