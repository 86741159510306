import { Block, Container, Heading, Item } from '~/components'
import type { LocationsBlock } from './types'

import * as styles from './LocationsBlock.css'

type Props = {
  block: LocationsBlock
}

export function LocationsBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <Heading margin="medium">{block.title}</Heading>
            <div className={styles.main}>
              {block.locations.map((location) => (
                <Item key={location.id} title={location.name} image={location.image} href={location.path} />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Block>
  )
}
