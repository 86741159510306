import type { StoryblokBlock, BlockTransformContext, StoryblokStory } from '~/lib/storyblok'
import type { Event } from '~/contentTypes/Event/types'
import type { EventsBlock } from './types'
import { transformEvent } from '~/contentTypes/Event'

export async function transformEventsBlock(
  block: StoryblokBlock,
  { props, options, context }: BlockTransformContext,
): Promise<EventsBlock> {
  // When are are more than 3 layers deeply nested, the Storyblok API doesn't resolve the relations anymore and we only receive an array of ids: ['12345677891234567', '12345677891234567'].
  // E.g. Chef -> Event -> Location -> Event. We never need that data, so we just return an empty array.
  if (block.events?.length && typeof block.events[0] === 'object') {
    var events = await Promise.all(
      block.events.map(async (story: StoryblokStory): Promise<Event> => {
        return await transformEvent(story, context)
      }),
    )
  } else {
    var events = []
  }

  return {
    ...props,
    title: block.title || null,
    events,
    options,
  }
}
