import { StoryblokBlock, BlockTransformContext } from '~/lib/storyblok'
import type { NewsletterBlock } from './types'

export async function transformNewsletterBlock(
  block: StoryblokBlock,
  { props, options }: BlockTransformContext,
): Promise<NewsletterBlock> {
  return {
    ...props,
    title: block.title || null,
    options: {
      ...options,
    },
  }
}
