import { useEffect, useState } from 'react'

import { Components } from './types'
import { transformContentType } from './transformContentType'

type Options = {
  preview?: boolean
  contentTypes?: Components
}

// TODO: Generics?
export function useStoryblok(initialStory: any, options: Options = {}) {
  const [story, setStory] = useState(initialStory)

  useEffect(() => {
    // TODO: decide when to use. based on options.preview or based on bridge loaded???
    if (!options.preview) {
      return
    }

    if (typeof window.storyblokRegisterEvent === 'undefined') {
      console.error(
        'Storyblok Bridge is disabled. Please enable it to use it. Read https://github.com/storyblok/storyblok-js',
      )

      return
    }

    window.storyblokRegisterEvent(() => {
      // TODO: Resolve relations globally.
      const sbBridge: any = new (window as any).StoryblokBridge({
        // TODO: Make dynamic.
        resolveRelations: ['MachinesBlock.machines', 'MachineCategoriesBlock.categories', 'ShopBlockSection.products'],
        resolveLinks: 'url', // TODO: this doesn't seem to work. makes no difference. WORKAROUND?
      })

      sbBridge.on(['input', 'published', 'change'], (event: any) => {
        if (event.action == 'input' && event.story.uuid === initialStory.id) {
          const newStory = transformContentType(event.story, options.contentTypes || {})
          setStory(newStory)
        } else {
          window.location.reload()
        }
      })
    })
  }, [])

  return story
}
