import { StoryblokBlock } from '~/lib/storyblok'
import { BlockOptions } from './types'

export function transformBlockOptions(block: StoryblokBlock): BlockOptions {
  return {
    background: block.option_background ? true : false,
    margin: block.option_margin || null,
    padding: block.option_padding || null,
  }
}
