import React from 'react'
import Image from 'next/image'
import classNames from 'classnames'

import { Container, Heading, Block } from '~/components'
import type { LogosBlock } from './types'

import * as styles from './LogosBlock.css'

type Props = {
  block: LogosBlock
}

export function LogosBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.content}>
          <div className={styles.header}>
            <Heading>{block.title}</Heading>
          </div>
          <div className={styles.main}>
            <div className={classNames([styles.logos, block.options.size === 'large' && styles.logosLarge])}>
              {block.logos.map((logo) => {
                if (logo.url) {
                  return (
                    <a href={logo.url} className={styles.logo} key={logo.id} target="_blank" rel="noreferrer">
                      <img src={logo.image.url} alt={logo.image.alt} className={styles.image} />
                    </a>
                  )
                }
                return (
                  <div className={styles.logo} key={logo.id}>
                    <img src={logo.image.url} alt={logo.image.alt} className={styles.image} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Container>
    </Block>
  )
}
