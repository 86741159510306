import { StoryblokStory, transformBlocks, TransformContext } from '~/lib/storyblok'
import { getDefaultContentTypeProperties, transformImage } from '~/lib/storyblok'

import type { Chef } from './types'

export async function transformChef(story: StoryblokStory, context: TransformContext): Promise<Chef> {
  const props = getDefaultContentTypeProperties(story)
  const blocks = await transformBlocks(story.content.blocks, context)

  return {
    ...props,
    teaser: story.content.teaser,
    image: transformImage(story.content.image),
    blocks,
  }
}
