import mime from 'mime'

import { StoryblokAsset, Asset } from './types'

function getNameFromFilename(asset: StoryblokAsset): string {
  const url = asset.filename.replace('https://', '')
  // [domain, prefix, space, x, id, filename]
  const [, , , , , filename] = url.split('/')

  // [name, extension]
  const [name] = filename.split('.')

  return name
}

/** Transforms any Storyblok assets type */
export function transformAsset(asset: StoryblokAsset): Asset {
  return {
    id: String(asset.id),
    name: asset.name || getNameFromFilename(asset),
    alt: asset.alt || null,
    title: asset.title || null,
    copyright: asset.copyright,
    url: asset.filename,
    mime: mime.getType(asset.filename) || '',
  }
}
