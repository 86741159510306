import type { Components } from '~/lib/storyblok'
import { Page, transformPage } from '~/contentTypes/Page'
import { Event, transformEvent } from '~/contentTypes/Event'
import { Location, transformLocation } from '~/contentTypes/Location'
import { Chef, transformChef } from '~/contentTypes/Chef'

export const contentTypes: Components = {
  Page: {
    component: Page,
    transform: transformPage,
  },
  Event: {
    component: Event,
    transform: transformEvent,
  },
  Location: {
    component: Location,
    transform: transformLocation,
  },
  Chef: {
    component: Chef,
    transform: transformChef,
  },
}
