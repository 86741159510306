import type { StoryblokBlock, BlockTransformContext, StoryblokStory } from '~/lib/storyblok'
import type { Location } from '~/contentTypes/Location/types'
import type { LocationsBlock } from './types'
import { transformLocation } from '~/contentTypes/Location'

export async function transformLocationsBlock(
  block: StoryblokBlock,
  { props, options, context }: BlockTransformContext,
): Promise<LocationsBlock> {
  const locations = await Promise.all(
    block.locations.map(async (story: StoryblokStory): Promise<Location> => {
      return await transformLocation(story, context)
    }),
  )

  return {
    ...props,
    title: block.title || null,
    locations,
    options,
  }
}
