import type { StoryblokLink, Link } from './types'
import { transformAsset } from './transformAsset'

function generateRandomId(): string {
  return Math.random().toString(36).substr(2, 5)
}

/** Checks if URL starts with an external protocal like https://, ftp://, ipfs:// etc. */
function isExternalProtocol(url: string): boolean {
  return /^([a-zA-Z]).*:\/\//g.test(url)
}

function isEmail(url: string): boolean {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    url,
  )
}

/**
 * Links:
 *
 * External:
 * https://www.example.com
 * http://example.com
 * ipfs://xyz
 * ftp://ftp.example.com
 * //example.com
 * mailto:hello@example.com
 * hello@example.com
 *
 * Internal:
 * /foo
 * foo
 * #foo
 * foo#bar
 */
function isLinkExternal(url: string): boolean {
  if (isExternalProtocol(url)) {
    return true
  }

  if (url.substring(0, 2) === '//') {
    return true
  }

  if (url.substring(0, 7) === 'mailto:') {
    return true
  }

  if (isEmail(url)) {
    return true
  }

  return false
}

function getURL(storyblokLink: StoryblokLink): string {
  switch (storyblokLink.linktype) {
    case 'story':
      // a story stores the cached_url as `about-us/` or `about-us/contact/`.
      // however we need the URL for next router as `/about-us` or `/about-us/contact`.
      // therefore we need to remove the last character / and prefix it.
      // TODO: check if same problem with type 'url'
      let url = storyblokLink.cached_url
      if (url.charAt(url.length - 1) === '/') {
        url = url.slice(0, -1)
      }
      return `/${url}`
    case 'url':
      return storyblokLink.url
    case 'email':
      return storyblokLink.email
    case 'asset':
      return storyblokLink.url
    default:
      return ''
  }
}

function getText(storyblokLink: StoryblokLink): string {
  switch (storyblokLink.linktype) {
    case 'story':
      return storyblokLink.story?.name || '' // IMPORTANT: needed because the bridge doesn't resolveLinks
    case 'asset':
      // Example: {
      //   "id": "",
      //   "url": "https://a.storyblok.com/f/134831/x/3745220eb0/2019_produktvergleich_lapreva_dusch_wcs.pdf",
      //   "linktype": "asset",
      //   "fieldtype": "multilink",
      //   "cached_url": "https://a.storyblok.com/f/134831/x/3745220eb0/2019_produktvergleich_lapreva_dusch_wcs.pdf"
      // }
      const asset = transformAsset({
        id: generateRandomId(),
        filename: storyblokLink.url,
        // name: 'Download', // TODO: Problem is, we don't have a download.
      })
      return asset.name
    default:
      return storyblokLink.url
  }
}

function getTarget(storyblokLink: StoryblokLink): string | undefined {
  const url = getURL(storyblokLink)
  return isLinkExternal(url) ? '_blank' : undefined
}

function getRel(storyblokLink: StoryblokLink): string | undefined {
  const url = getURL(storyblokLink)
  return isLinkExternal(url) ? 'noopener noreferrer' : undefined
}

export function transformLink(storyblokLink: StoryblokLink): Link {
  const link = {
    type: storyblokLink.linktype,
    url: getURL(storyblokLink),
    text: getText(storyblokLink),
    target: getTarget(storyblokLink),
    rel: getRel(storyblokLink),
  }

  // Preventing error: "`undefined` cannot be serialized as JSON. Please use `null` or omit this value."
  // See https://github.com/vercel/next.js/discussions/11209
  return JSON.parse(JSON.stringify(link))
}
