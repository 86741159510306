import type { StoryblokBlock, BlockTransformContext, StoryblokStory } from '~/lib/storyblok'
import type { Event } from '~/contentTypes/Event/types'
import type { EventsCalendarBlock } from './types'
import { transformEvent } from '~/contentTypes/Event'
import { relations } from '~/storyblok/relations'

export async function transformEventsCalendarBlock(
  block: StoryblokBlock,
  { props, options, context }: BlockTransformContext,
): Promise<EventsCalendarBlock> {
  const stories = await context.client.getAll('cdn/stories', {
    // TODO: correctly load 'draft' or 'published'.
    version: context.preview || process.env.STORYBLOK_FORCE_DRAFT ? 'draft' : 'published',
    // resolve_relations: relations?.join(',') || '',
    resolve_links: 'url',
    starts_with: 'eventkalender/',
    is_startpage: false,
  })

  const events = await Promise.all(
    stories.map(async (story: StoryblokStory): Promise<Event> => {
      return await transformEvent(story, context)
    }),
  )

  const sortedEvents = events.sort((a, b) => a.date.localeCompare(b.date))

  let eventsByDay: { [key: string]: Event[] } = {}

  for (const event of sortedEvents) {
    const date = `${event.date.slice(0, 10)} 00:00:00`
    if (date in eventsByDay) {
      eventsByDay[date].push(event)
    } else {
      eventsByDay[date] = [event]
    }
  }

  return {
    ...props,
    eventsByDay,
    options,
  }
}
