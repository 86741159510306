import { Components, StoryblokStory } from './types'

export function transformContentType(story: StoryblokStory, contentTypes: Components): StoryblokStory {
  const { transform } = contentTypes[story.content.component]
  if (transform) {
    return transform(story)
  }

  return story
}
