import type { StoryblokStory, TransformContext } from '~/lib/storyblok'
import { getDefaultContentTypeProperties, transformImage, transformBlocks } from '~/lib/storyblok'
import { transformLocation } from '~/contentTypes/Location'
import type { Event } from './types'

export async function transformEvent(story: StoryblokStory, context: TransformContext): Promise<Event> {
  const props = getDefaultContentTypeProperties(story)
  const blocks = await transformBlocks(story.content.blocks, context)

  var location

  if (typeof story.content.location === 'object') {
    location = await transformLocation(story.content.location, context)
  }

  return {
    ...props,
    type: story.content.type,
    image: transformImage(story.content.image),
    date: story.content.date,
    location: location || null,
    teaser: story.content.teaser || null,
    soldout: story.content.soldout || false,
    blocks,
  }
}
