import { StoryblokBlock, BlockTransformContext, getDefaultBlockProperties } from '~/lib/storyblok'
import { transformImage } from '~/lib/storyblok'
import type { LogosBlock, LogosBlockLogo } from './types'

function transformLogosBlockLogo(block: StoryblokBlock): LogosBlockLogo {
  const props = getDefaultBlockProperties(block)

  return {
    ...props,
    image: transformImage(block.image),
    url: block.url,
  }
}

export async function transformLogosBlock(
  block: StoryblokBlock,
  { props, options }: BlockTransformContext,
): Promise<LogosBlock> {
  const logos = block.logos.map(transformLogosBlockLogo)

  return {
    ...props,
    title: block.title,
    logos,
    options: {
      ...options,
      size: block.option_size,
    },
  }
}
