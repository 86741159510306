import { Block, Intro } from '~/components'
import type { IntroBlock } from './types'

type Props = {
  block: IntroBlock
}

export function IntroBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Intro title={block.title} />
    </Block>
  )
}
