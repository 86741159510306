import { StoryblokBlock, BlockTransformContext, transformImage, getDefaultBlockProperties } from '~/lib/storyblok'
import type { HostsBlockHost, HostsBlock } from './types'

function transformHostsBlockHost(block: StoryblokBlock): HostsBlockHost {
  const props = getDefaultBlockProperties(block)

  return {
    ...props,
    image: transformImage(block.image),
    text: block.text,
    meta: block.meta,
  }
}

export async function transformHostsBlock(
  block: StoryblokBlock,
  { props, options }: BlockTransformContext,
): Promise<HostsBlock> {
  const hosts = block.hosts.map(transformHostsBlockHost)

  return {
    ...props,
    title: block.title,
    hosts,
    options,
  }
}
