import { Block, Container, Heading, RichText, Button } from '~/components'
import type { LocationInfoBlock } from './types'

import * as styles from './LocationInfoBlock.css'

type Props = {
  block: LocationInfoBlock
}

export function LocationInfoBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.content}>
          <div className={styles.intro}>
            <Heading margin="medium">Informationen</Heading>
          </div>
          <div className={styles.contact}>
            <strong>Kontakt</strong>
            <br />
            <RichText content={block.contact} />
            {block.website && (
              <div className={styles.action}>
                <Button href={block.website.url} target="_blank" external>
                  Website
                </Button>
              </div>
            )}
          </div>
          <div className={styles.hours}>
            <strong>Öffnungszeiten</strong>
            <br />
            <RichText content={block.hours} />
          </div>
          <div className={styles.meta}>
            {block.title && (
              <>
                <strong>{block.title}</strong>
                <br />
              </>
            )}
            <RichText content={block.meta} />
            {block.downloads && (
              <ul className={styles.downloadList}>
                {block.downloads.map((download) => (
                  <li key={download.id}>
                    <a href={download.url} target="_blank" rel="noreferrer" className={styles.downloadLink}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className={styles.downloadIcon}>
                        <g
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={3}
                        >
                          <path d="M28.5 19.5v6a3 3 0 0 1-3 3h-21a3 3 0 0 1-3-3v-6" />
                          <path d="m7.5 12 7.5 7.5 7.5-7.5" />
                          <path d="M15 19.5v-18" />
                        </g>
                      </svg>
                      {download.name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </Container>
    </Block>
  )
}
