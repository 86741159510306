import { Block, Container, Heading, Item } from '~/components'
import type { EventsBlock } from './types'
import { formatDate } from '~/contentTypes/Event/utils'

import * as styles from './EventsBlock.css'

type Props = {
  block: EventsBlock
}

export function EventsBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <Heading margin="large">{block.title}</Heading>
            <div className={styles.main}>
              {block.events.map((event) => (
                <Item
                  key={event.id}
                  title={event.name}
                  meta={formatDate(event.date)}
                  image={event.image}
                  href={event.path}
                  type={event.type}
                  teaser={event.teaser || undefined}
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Block>
  )
}
