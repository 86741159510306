import Image from 'next/image'

import { Block, Heading, Item, OverviewBox, Title, Button } from '~/components'
import type { HeroBlock } from './types'
import { formatDate } from '~/contentTypes/Event/utils'

import * as styles from './HeroBlock.css'

type Props = {
  block: HeroBlock
}

export function HeroBlock({ block }: Props) {
  const image = block.images[0]

  return (
    <Block options={block.options}>
      <div className={styles.hero}>
        <div className={styles.content}>
          <div className={styles.grid}>
            <div className={styles.main}>
              <div className={styles.mainIntro}>
                <Title hyphens={false} animation shadowStrong>
                  {block.title}
                </Title>
              </div>
              <div className={styles.mainMedia}>
                <Image src={image.url} alt={image.alt} layout="fill" objectFit="cover" />
              </div>
            </div>
            <div className={styles.highlight}>
              <div className={styles.highlightCanvas}>
                <OverviewBox
                  title={block.highlight.title}
                  description={block.highlight.teaser}
                  image={block.highlight.image}
                  href={block.highlight.link.url}
                  aspectRatio={false}
                  button="Übersicht Köche und Menus"
                  shadow
                  target="_blank"
                />
              </div>
            </div>
            <div className={styles.events}>
              <Heading>Events</Heading>
              <div className={styles.eventsItems}>
                {block.events.map((event) => (
                  <div className={styles.eventsItem} key={event.id}>
                    <Item
                      title={event.name}
                      meta={formatDate(event.date)}
                      image={event.image}
                      href={event.path}
                      showButton={false}
                      showIcon={true}
                      type={event.type}
                    />
                  </div>
                ))}
              </div>
              <div className={styles.eventsAction}>
                <Button href="/eventkalender" color="primary">
                  Zum Eventkalender
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Block>
  )
}
