import React from 'react'

import type { Block, Components } from './types'
import { editable } from './editable'

type Props = {
  block: Block
  blocks: Components
  [k: string]: any
}

function getBlock(component: string, blocks: Components) {
  if (!component || !blocks[component]) {
    console.error(`Block ${component} doesn't exist.`)
    return null
  }

  return blocks[component].component
}

export function BlockComponent({ block, blocks, ...props }: Props) {
  const Component: any = getBlock(block.component, blocks)
  if (!Component) {
    return null
  }

  // return React.cloneElement(<Component />, {
  //   block,
  //   'data-decorator-attr': 'foo',
  // })

  // return React.cloneElement(Component, { block, key: block.id, ...storyblokEditable(block), ...props })

  return (
    <div key={block.id} {...editable(block)}>
      <Component block={block} {...props} />
    </div>
  )
}
