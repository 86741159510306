import { Components } from '~/lib/storyblok'

import { EventsBlock, transformEventsBlock } from '~/blocks/EventsBlock'
import { EventsOverviewBlock, transformEventsOverviewBlock } from '~/blocks/EventsOverviewBlock'
import { IntroBlock, transformIntroBlock } from '~/blocks/IntroBlock'
import { MediaTextBlock, transformMediaTextBlock } from '~/blocks/MediaTextBlock'
import { MediaListBlock, transformMediaListBlock } from '~/blocks/MediaListBlock'
import { TextBlock, transformTextBlock } from '~/blocks/TextBlock'
import { LocationsOverviewBlock, transformLocationsOverviewBlock } from '~/blocks/LocationsOverviewBlock'
import { ChefsOverviewBlock, transformChefsOverviewBlock } from '~/blocks/ChefsOverviewBlock'
import { GalleryBlock, transformGalleryBlock } from '~/blocks/GalleryBlock'
import { LocationsBlock, transformLocationsBlock } from '~/blocks/LocationsBlock'
import { LocationInfoBlock, transformLocationInfoBlock } from '~/blocks/LocationInfoBlock'
import { HeroBlock, transformHeroBlock } from '~/blocks/HeroBlock'
import { HostsBlock, transformHostsBlock } from '~/blocks/HostsBlock'
import { LogosBlock, transformLogosBlock } from '~/blocks/LogosBlock'
import { EventsCalendarBlock, transformEventsCalendarBlock } from '~/blocks/EventsCalendarBlock'
import { HTMLFormBlock, transformHTMLFormBlock } from '~/blocks/HTMLFormBlock'
import { VideoBlock, transformVideoBlock } from '~/blocks/VideoBlock'
import { NewsletterBlock, transformNewsletterBlock } from '~/blocks/NewsletterBlock'
import { GalleryGridBlock, transformGalleryGridBlock } from '~/blocks/GalleryGridBlock'

export const blocks: Components = {
  EventsBlock: {
    component: EventsBlock,
    transform: transformEventsBlock,
  },
  EventsOverviewBlock: {
    component: EventsOverviewBlock,
    transform: transformEventsOverviewBlock,
  },
  ChefsOverviewBlock: {
    component: ChefsOverviewBlock,
    transform: transformChefsOverviewBlock,
  },
  IntroBlock: {
    component: IntroBlock,
    transform: transformIntroBlock,
  },
  MediaTextBlock: {
    component: MediaTextBlock,
    transform: transformMediaTextBlock,
  },
  MediaListBlock: {
    component: MediaListBlock,
    transform: transformMediaListBlock,
  },
  TextBlock: {
    component: TextBlock,
    transform: transformTextBlock,
  },
  LocationsOverviewBlock: {
    component: LocationsOverviewBlock,
    transform: transformLocationsOverviewBlock,
  },
  GalleryBlock: {
    component: GalleryBlock,
    transform: transformGalleryBlock,
  },
  LocationsBlock: {
    component: LocationsBlock,
    transform: transformLocationsBlock,
  },
  LocationInfoBlock: {
    component: LocationInfoBlock,
    transform: transformLocationInfoBlock,
  },
  HeroBlock: {
    component: HeroBlock,
    transform: transformHeroBlock,
  },
  HostsBlock: {
    component: HostsBlock,
    transform: transformHostsBlock,
  },
  LogosBlock: {
    component: LogosBlock,
    transform: transformLogosBlock,
  },
  EventsCalendarBlock: {
    component: EventsCalendarBlock,
    transform: transformEventsCalendarBlock,
  },
  HTMLFormBlock: {
    component: HTMLFormBlock,
    transform: transformHTMLFormBlock,
  },
  VideoBlock: {
    component: VideoBlock,
    transform: transformVideoBlock,
  },
  NewsletterBlock: {
    component: NewsletterBlock,
    transform: transformNewsletterBlock,
  },
  GalleryGridBlock: {
    component: GalleryGridBlock,
    transform: transformGalleryGridBlock,
  },
}
