import { BlockComponent } from '~/lib/storyblok'
import { blocks } from '~/storyblok/blocks'
import { Page as PageType } from './types'

import * as styles from './Page.css'

type Props = {
  story: PageType
}

export function Page({ story }: Props) {
  return (
    <div className={styles.wrapper}>
      {story.blocks?.map((block) => (
        <BlockComponent block={block} blocks={blocks} key={block.id} parentContentType="Page" />
      ))}
    </div>
  )
}
