import { Block, Container, RichText, Heading } from '~/components'
import type { TextBlock } from './types'

import * as styles from './TextBlock.css'

type Props = {
  block: TextBlock
}

export function TextBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.content}>
          {block.title && (
            <div className={styles.intro}>
              <Heading margin="small" as="h2" level={block.options.heading}>
                {block.title}
              </Heading>
            </div>
          )}
          {block.richtext && (
            <div className={block.options.align === 'left' ? styles.mainLeft : styles.main}>
              <RichText content={block.richtext} />
            </div>
          )}
        </div>
      </Container>
    </Block>
  )
}
