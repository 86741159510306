import type { StoryblokBlock, BlockTransformContext } from '~/lib/storyblok'
import type { Chef } from '~/contentTypes/Chef/types'
import type { ChefsOverviewBlock } from './types'
import { transformChef } from '~/contentTypes/Chef/transformChef'
import { StoryblokStory } from '~/lib/storyblok'

export async function transformChefsOverviewBlock(
  block: StoryblokBlock,
  { props, options, context }: BlockTransformContext,
): Promise<ChefsOverviewBlock> {
  const chefs = await Promise.all(
    block.chefs.map(async (story: StoryblokStory): Promise<Chef> => {
      return await transformChef(story, context)
    }),
  )

  return {
    ...props,
    chefs,
    options,
  }
}
