import type { StoryblokBlock, BlockTransformContext, StoryblokStory } from '~/lib/storyblok'
import { transformImage, transformLink } from '~/lib/storyblok'
import type { Event } from '~/contentTypes/Event/types'
import type { HeroBlock } from './types'
import { transformEvent } from '~/contentTypes/Event'

export async function transformHeroBlock(
  block: StoryblokBlock,
  { props, options, context }: BlockTransformContext,
): Promise<HeroBlock> {
  const events = await Promise.all(
    block.events.map(async (story: StoryblokStory): Promise<Event> => {
      return await transformEvent(story, context)
    }),
  )

  const highlight = {
    image: transformImage(block.highlight_image),
    title: block.highlight_title,
    teaser: block.highlight_teaser,
    link: transformLink(block.highlight_link),
  }

  return {
    ...props,
    title: block.title || null,
    images: block.images.map(transformImage),
    events,
    highlight,
    options,
  }
}
