import type { StoryblokStory, TransformContext } from '~/lib/storyblok'
import { getDefaultContentTypeProperties, transformImage, transformBlocks } from '~/lib/storyblok'

import type { Location } from './types'

export async function transformLocation(story: StoryblokStory, context: TransformContext): Promise<Location> {
  const props = getDefaultContentTypeProperties(story)
  const blocks = await transformBlocks(story.content.blocks, context)

  return {
    ...props,
    type: story.content.type,
    image: transformImage(story.content.image),
    teaser: story.content.teaser,
    blocks,
  }
}
