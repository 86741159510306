import { StoryblokStory, ContentType } from './types'

/** Gets the common content type properties from a Storyblok Story such as id, slug, path etc. */
export function getDefaultContentTypeProperties(story: StoryblokStory): ContentType {
  return {
    id: story.uuid,
    name: story.name,
    slug: story.slug,
    path: `/${story.full_slug}`,
    component: story.content.component,
    publishAt: story.first_published_at || null,
    publishedAt: story.published_at || null,
    content: {
      _editable: story.content._editable || null, // only available in draft mode (not published) with preview token
    },
  }
}
