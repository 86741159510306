import type { StoryblokBlock, BlockTransformContext } from '~/lib/storyblok'
import type { HTMLFormBlock } from './types'

export async function transformHTMLFormBlock(
  block: StoryblokBlock,
  { props, options }: BlockTransformContext,
): Promise<HTMLFormBlock> {
  return {
    ...props,
    html: block.html,
    options,
  }
}
