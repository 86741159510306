import { BlockComponent } from '~/lib/storyblok'
import { blocks } from '~/storyblok/blocks'
import type { Event } from './types'
import { Intro, Container, BackButton, Heading, Item } from '~/components'

import * as styles from './Event.css'

type Props = {
  story: Event
}

export function Event({ story }: Props) {
  return (
    <>
      <div className={styles.intro}>
        <Intro title={story.name} label={story.soldout ? <div className={styles.soldout}>ausgebucht</div> : null} />
      </div>
      <Container>
        <div className={styles.back}>
          <BackButton>zurück</BackButton>
        </div>
      </Container>
      {story.blocks?.map((block) => (
        <BlockComponent block={block} blocks={blocks} key={block.id} parentContentType="Event" />
      ))}
      {story.location && (
        <Container>
          <div className={styles.location}>
            <div className={styles.locationIntro}>
              <Heading>Ort des Events</Heading>
            </div>
            <div className={styles.locationContent}>
              <Item
                title={story.location.name}
                teaser={story.location.teaser}
                image={story.location.image}
                href={story.location.path}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  )
}
