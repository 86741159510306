import { StoryblokStory, getDefaultContentTypeProperties, transformBlocks, TransformContext } from '~/lib/storyblok'
import { Page } from './types'

export async function transformPage(story: StoryblokStory, context: TransformContext): Promise<Page> {
  const props = getDefaultContentTypeProperties(story)
  const blocks = await transformBlocks(story.content.blocks, context)

  return {
    ...props,
    blocks,
  }
}
