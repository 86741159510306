import { Block, Button, Container, Heading } from '~/components'
import * as styles from './NewsletterBlock.css'
import type { NewsletterBlock } from './types'

type Props = {
  block: NewsletterBlock
}

export function NewsletterBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.content}>
          {block.title && (
            <div className={styles.intro}>
              <Heading margin="small" as="h2" level={block.options.heading}>
                {block.title}
              </Heading>
            </div>
          )}
          <div className={styles.main}>
            <div className={styles.box}>
              <form
                action="https://genussfestival.us21.list-manage.com/subscribe/post?u=6ffe2aaaf8f1f6c8875da720c&amp;id=ab620916e7&amp;f_id=007889e6f0"
                method="post"
                name="mc-embedded-subscribe-form"
                target="_blank"
                noValidate
                onSubmit={(ev: any) =>
                  setTimeout(() => {
                    ev.target.reset()
                  }, 500)
                }
              >
                <input type="hidden" name="tags" value="2916884" />
                <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                  <input type="text" name="b_6ffe2aaaf8f1f6c8875da720c_ab620916e7" tabIndex={-1} defaultValue="" />
                </div>
                <div className={styles.group}>
                  <input
                    type="email"
                    name="EMAIL"
                    required
                    defaultValue=""
                    placeholder="Deine E-Mail-Adresse"
                    className={styles.groupInput}
                  />
                  <Button type="submit" name="subscribe" value="Anmelden" className={styles.groupButton}>
                    Anmelden
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </Block>
  )
}
