import { StoryblokAsset } from '.'

export function hasImage(image?: StoryblokAsset): boolean {
  if (!image) {
    return false
  }

  if (image.filename) {
    return true
  }

  return false
}
