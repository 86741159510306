import { Block, Container, Heading, YouTubeVideo } from '~/components'
import * as styles from './VideoBlock.css'
import { VideoBlock } from './types'

type Props = {
  block: VideoBlock
}

export function VideoBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.content}>
          <div className={styles.main}>
            {block.title && (
              <div className={styles.header}>
                <Heading margin="medium">{block.title}</Heading>
              </div>
            )}
            <YouTubeVideo url={block.youtube} previewImage={block.preview || undefined} />
          </div>
        </div>
      </Container>
    </Block>
  )
}
