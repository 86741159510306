import { parseISO, format, isPast, isToday } from 'date-fns'
import { de } from 'date-fns/locale'
import classNames from 'classnames'

import { Block, Container, Heading, Item } from '~/components'
import type { EventsCalendarBlock } from './types'
import { formatDate } from '~/contentTypes/Event/utils'

import * as styles from './EventsCalendarBlock.css'

type Props = {
  block: EventsCalendarBlock
}

export function EventsCalendarBlock({ block }: Props) {
  const onScrollTo = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.calendar}>
              <Heading margin="medium">Eventkalender</Heading>
              <ul className={styles.calendarList}>
                {Object.keys(block.eventsByDay).map((day) => {
                  const date = parseISO(day)
                  const past = isPast(date)
                  const today = isToday(date)

                  return (
                    <li key={day} className={classNames([styles.calendarItem, past && styles.calendarItemPast])}>
                      <a
                        href={`#event-${day}`}
                        className={styles.calendarLink}
                        onClick={(e) => {
                          e.preventDefault()
                          onScrollTo(`event-${day}`)
                        }}
                      >
                        <span className={classNames([styles.calendarButton, today && styles.calendarButtonToday])}>
                          {format(date, 'EEEEEE', { locale: de })}
                        </span>
                        <span className={styles.calendarHint}>{format(date, 'd. MMMM')}</span>
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className={styles.days}>
              {Object.entries(block.eventsByDay).map(([day, events]) => {
                const date = parseISO(day)
                return (
                  <div className={styles.day} key={day} id={`event-${day}`}>
                    <Heading margin="large">{format(date, 'eeee, d. MMMM yyyy', { locale: de })}</Heading>
                    <div className={styles.main}>
                      {events.map((event) => (
                        <Item
                          key={event.id}
                          title={event.name}
                          meta={formatDate(event.date)}
                          image={event.image}
                          href={event.path}
                          type={event.type}
                          teaser={event.teaser || undefined}
                          soldout={event.soldout}
                        />
                      ))}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Container>
    </Block>
  )
}
