import { StoryblokBlock, Block } from './types'

export function getDefaultBlockProperties(block: StoryblokBlock | any): Block {
  return {
    id: block._uid,
    component: block.component,

    // _editable is required for Storyblok to connect the Visual Editor.
    _editable: block._editable ? String(block._editable) : '',
    _uid: block._uid,
  }
}
