import {
  StoryblokBlock,
  BlockTransformContext,
  hasLink,
  transformLink,
  transformAsset,
  StoryblokAsset,
} from '~/lib/storyblok'
import type { LocationInfoBlock } from './types'

export async function transformLocationInfoBlock(
  block: StoryblokBlock,
  { props, options }: BlockTransformContext,
): Promise<LocationInfoBlock> {
  return {
    ...props,
    contact: block.contact,
    hours: block.hours,
    title: block.title || null,
    meta: block.meta,
    website: hasLink(block.website) ? transformLink(block.website) : null,
    downloads:
      block.downloads && block.downloads.length
        ? block.downloads?.map((asset: StoryblokAsset) => transformAsset(asset))
        : null,
    options,
  }
}
