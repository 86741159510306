import { storyblokEditable } from '@storyblok/js'

import { Block } from './types'

export function editable(block: Block) {
  if (!block?._editable) {
    return null
  }

  return storyblokEditable(block)
}
