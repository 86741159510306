import { StoryblokAsset } from './types'

type Options = {
  width?: number
  height?: number
  quality?: number
  format?: 'jpg' | 'png' | 'webp'
  /** true for transparent or a fill color such as `FFFFFF` or `CCCCCC` without the #. */
  fill?: true | string
  smartCrop?: boolean
  focal?: boolean
}

export type Image = {
  url: string
  width: number
  height: number
  /** file name without extension */
  name: string
  /** filename with extension */
  filename: string
  /** Description in asset field (inline) */
  description?: string
  /** Alt text in Storyblok image editor; can be an empty string */
  alt: string
  /** Title in Storyblok image editor */
  title?: string
  /** Copyright in Storyblok image editor */
  copyright?: string
  /** Source in Storyblok image editor */
  source?: string
  /** The original filename from Storyblok */
  file: string
}

export function getSize(url: string): { width: number; height: number } {
  const filename = url.replace('https://a.storyblok.com/f/', '')
  const parts = filename.split('/')

  const size = parts[1].split('x')

  return {
    width: parseInt(size[0]),
    height: parseInt(size[1]),
  }
}

/** Gets the name without the file extension */
export function getName(url: string): string {
  const parts = url.split('/')
  const nameWithExtension = parts[parts.length - 1]
  const nameWithoutExtension = nameWithExtension.split('.')[0]

  return nameWithoutExtension
}

/** Gets the filename (with extension)  */
export function getFilename(url: string): string {
  const parts = url.split('/')
  const filenameWithExtension = parts[parts.length - 1]

  return filenameWithExtension
}

export function hasImage(image: StoryblokAsset | any) {
  return image && image.filename
}

export function transformImageResize(image: StoryblokAsset, options: Options = {}): Image {
  if (!hasImage(image)) {
    throw new Error('image is required')
  }

  const name = getName(image.filename)

  if (options.width || options.height) {
    let width = options.width || 0
    let height = options.height || 0

    let url = `${image.filename}/m/${width}x${height}`

    const originalSize = getSize(image.filename)

    // Calculate the new width if not provided.
    if (!options.width) {
      width = Math.round((height / originalSize.height) * originalSize.width)
    }

    // Calculate the new height if not provided.
    if (!options.height) {
      height = Math.round((width / originalSize.width) * originalSize.height)
    }

    if (options.smartCrop) {
      url += '/smart'
    }

    if (options.focal) {
      url += `/filters:focal(${image.focus})`
    }

    if (options.fill) {
      if (options.fill === true) {
        url += '/fill(transparent):format(png)'
      } else {
        url += `/filters:fill(${options.fill})`
      }
    }

    return {
      url,
      width,
      height,
      name,
      filename: getFilename(image.filename),
      description: image.name,
      alt: image.alt || '',
      copyright: image.copyright,
      title: image.title || '',
      source: image.source,
      file: image.filename,
    }
  }

  const size = getSize(image.filename)

  return {
    url: image.filename,
    ...size,
    name,
    filename: getFilename(image.filename),
    description: image.name,
    alt: image.alt || '',
    copyright: image.copyright,
    title: image.title || '',
    source: image.source,
    file: image.filename,
  }
}
