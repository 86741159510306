import type { StoryblokBlock, BlockTransformContext } from '~/lib/storyblok'
import { transformImage } from '~/lib/storyblok'
import type { GalleryBlock } from './types'
import { transformImageResize } from '~/lib/storyblok/transformImageResize'

export async function transformGalleryBlock(
  block: StoryblokBlock,
  { props, options }: BlockTransformContext,
): Promise<GalleryBlock> {
  // const images = block.images.map(transformImage)
  let images = block.images.map((image: any) => transformImageResize(image, { height: 1080 }))

  images = JSON.parse(JSON.stringify(images))

  return {
    ...props,
    images,
    options,
  }
}
