import { StoryblokBlock, BlockTransformContext } from '~/lib/storyblok'
import type { TextBlock } from './types'

export async function transformTextBlock(
  block: StoryblokBlock,
  { props, options }: BlockTransformContext,
): Promise<TextBlock> {
  return {
    ...props,
    title: block.title,
    richtext: block.richtext,
    options: {
      ...options,
      heading: block.option_heading || 'h1',
      align: block.option_align || null,
    },
  }
}
