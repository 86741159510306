import type { StoryblokBlock, BlockTransformContext } from '~/lib/storyblok'
import type { IntroBlock } from './types'

export async function transformIntroBlock(
  block: StoryblokBlock,
  { props, options }: BlockTransformContext,
): Promise<IntroBlock> {
  return {
    ...props,
    title: block.title,
    options,
  }
}
