import Image from 'next/image'
import classNames from 'classnames'

import { Block, Container, OverviewBox } from '~/components'
import type { LocationsOverviewBlock } from './types'

import * as styles from './LocationsOverviewBlock.css'

type Props = {
  block: LocationsOverviewBlock
}

export function LocationsOverviewBlock({ block }: Props) {
  const filteredLocations = block.excludeRathaus
    ? block.locations.filter((location) => !(location.slug === 'rathausplatz' || location.slug === 'rathaussaal'))
    : block.locations

  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.events}>
          {filteredLocations.map((location, index) => (
            <OverviewBox
              key={index}
              title={location.name}
              image={location.image}
              href={location.path}
              type={location.type}
              shadow
            />
          ))}
        </div>
      </Container>
    </Block>
  )
}
