import { Block, Container, OverviewBox } from '~/components'
import type { EventsOverviewBlock } from './types'

import * as styles from './EventsOverviewBlock.css'

type Props = {
  block: EventsOverviewBlock
}

export function EventsOverviewBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.events}>
          {block.events.map((event, index) => (
            <OverviewBox
              key={index}
              title={event.name}
              description={event.teaser}
              image={event.image}
              href={event.path}
            />
          ))}
        </div>
      </Container>
    </Block>
  )
}
