import {
  StoryblokBlock,
  BlockTransformContext,
  transformImage,
  hasLink,
  transformLink,
  getDefaultBlockProperties,
  hasRichText,
} from '~/lib/storyblok'
import type { MediaListBlockItem, MediaListBlock } from './types'

function transformMediaListBlockItem(block: StoryblokBlock): MediaListBlockItem {
  const props = getDefaultBlockProperties(block)

  return {
    ...props,
    title: block.title,
    text: block.text,
  }
}

export async function transformMediaListBlock(
  block: StoryblokBlock,
  { props, options }: BlockTransformContext,
): Promise<MediaListBlock> {
  const items = block.items.map(transformMediaListBlockItem)

  return {
    ...props,
    image: transformImage(block.image),
    title: block.title,
    text: hasRichText(block.text) ? block.text : null,
    items,
    cta: hasLink(block.cta_link)
      ? {
          link: transformLink(block.cta_link),
          label: block.cta_label || null,
        }
      : null,
    options,
  }
}
