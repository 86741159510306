import { StoryblokBlock, BlockTransformContext, transformImage, hasLink, transformLink } from '~/lib/storyblok'
import type { MediaTextBlock } from './types'

export async function transformMediaTextBlock(
  block: StoryblokBlock,
  { props, options }: BlockTransformContext,
): Promise<MediaTextBlock> {
  return {
    ...props,
    image: block.image?.filename ? transformImage(block.image) : null,
    youtube: block.youtube || null,
    title: block.title,
    richtext: block.richtext,
    cta: hasLink(block.cta_link)
      ? {
          link: transformLink(block.cta_link),
          label: block.cta_label || null,
        }
      : null,
    options: {
      ...options,
      order: block.option_order || 'text_image',
    },
  }
}
