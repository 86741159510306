import { StoryblokBlock, BlockTransformContext, transformImage } from '~/lib/storyblok'
import type { VideoBlock } from './types'

export async function transformVideoBlock(
  block: StoryblokBlock,
  { props, options }: BlockTransformContext,
): Promise<VideoBlock> {
  return {
    ...props,
    title: block.title || null,
    preview: block.preview?.filename ? transformImage(block.preview) : null,
    youtube: block.youtube,
    options: {
      ...options,
    },
  }
}
