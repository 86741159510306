import mime from 'mime'

import { StoryblokAsset, Image, ImageOrientation } from './types'
import { hasImage } from './hasImage'

/** Example: `https://a.storyblok.com/f/162164/2880x1395/7020911ab1/slider.jpg` */
type AssetURLParts = {
  /** `f` */
  prefix: string
  /** `117109` */
  spaceID: string
  /** 336 */
  width: number
  /** 360 */
  height: number
  /** `portrait` */
  orientation: ImageOrientation
  /** `a5927815c5` */
  uniqueID: string
  /** `slider.jpg` */
  filename: string
}

function getAssetURLParts(assetURL: string): AssetURLParts {
  const path = assetURL.replace('https://a.storyblok.com/', '')
  const [prefix, spaceID, size, uniqueID, filename] = path.split('/')

  const sizes = size.split('x')
  const width = Number(sizes[0])
  const height = Number(sizes[1])
  const orientation = width > height ? 'landscape' : height > width ? 'portrait' : 'square'

  return {
    prefix,
    spaceID,
    width,
    height,
    orientation,
    uniqueID,
    filename,
  }
}

export function transformImage(imageAsset: StoryblokAsset): Image {
  if (!hasImage(imageAsset)) {
    throw new Error('`imageAsset` is required')
  }

  const parts = getAssetURLParts(imageAsset.filename)

  return {
    id: String(imageAsset.id),
    url: imageAsset.filename,
    width: parts.width,
    height: parts.height,
    orientation: parts.orientation,
    alt: imageAsset.alt || '',
    name: imageAsset.name || '', // TODO: get name of filename instead?
    title: imageAsset.title || '',
    copyright: imageAsset.copyright,
    asset: imageAsset.filename,
    mime: mime.getType(imageAsset.filename) || '',
  }
}
