import type { StoryblokBlock, BlockTransformContext, StoryblokStory } from '~/lib/storyblok'
import type { Event } from '~/contentTypes/Event/types'
import type { EventsOverviewBlock } from './types'
import { transformEvent } from '~/contentTypes/Event'

export async function transformEventsOverviewBlock(
  block: StoryblokBlock,
  { props, options, context }: BlockTransformContext,
): Promise<EventsOverviewBlock> {
  const events = await Promise.all(
    block.events.map(async (story: StoryblokStory): Promise<Event> => {
      return await transformEvent(story, context)
    }),
  )

  return {
    ...props,
    events,
    options,
  }
}
