import * as styles from './HTMLFormBlock.css'
import { Block, Container } from '~/components'
import type { HTMLFormBlock } from './types'

type Props = {
  block: HTMLFormBlock
}

export function HTMLFormBlock({ block }: Props) {
  return (
    <Block options={block.options}>
      <Container>
        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.html} dangerouslySetInnerHTML={{ __html: block.html }} />
          </div>
        </div>
      </Container>
    </Block>
  )
}
